<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="form-inline">
              <label>
                Show&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.per_page"
                  class="ml-1 mr-1"
                  size="sm"
                  :options="[10, 15, 25, 50, 100]"
                  @change="getReviews"
              ></b-form-select>
              <label class="ml-3">
                Review Filter:&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.review_rating"
                  class="ml-1 mr-1 status-filter"
                  size="sm"
                  :options="[{ value: 1, text: '1 Star' },{ value: 2, text: '2 Stars' },{ value: 3, text: '3 Stars' },{ value: 4, text: '4 Stars' },{ value: 5, text: '5 Stars' }, { value: 0, text: 'All' }]"
                  @change="getReviews"
              ></b-form-select>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
              <vue-json-to-csv :json-data="reviews" :csv-title="csvTitle()">
                <button class="btn btn-dark-blue btn-sm mr-3"><Feather class="icon-xs" type="download" /> Export</button>
              </vue-json-to-csv>
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getReviews"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <template v-if="!loadingBusinesses">
              <div class="table-responsive mb-0">
                <b-table
                  :items="reviews"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  data-export-types="['csv']"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(review_text)="row">
                    <strong class="d-block">Original Review:</strong>
                    <!-- eslint-disable-next-line-->
                    <div v-html="row.item.review_text"></div>
                    <div v-if="row.item.reply || row.item.writing_reply" class="mt-2">
                      <!-- eslint-disable-next-line-->
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="w-100">
                          <div v-if="row.item.waiting_4_ai || row.item.writing_reply && !row.item.editing">
                            <div v-if="row.item.waiting_4_ai">
                              Waiting for AI to write a reply <b-spinner small />
                            </div>
                            <!-- eslint-disable-next-line-->
                            <TypeWriter v-else-if="!row.item.editing" :typing-speed="35" :text="row.item.reply" @completed="row.item.stopped_typing = true" />
                          </div>
                          <textarea v-else-if="row.item.editing" v-model="row.item.reply" :disabled="!row.item.editing" class="form-control"></textarea>
                          <div v-else>
                            <strong class="d-block">Reply:</strong>
                            {{row.item.reply}}
                          </div>
                        </div>
                        <div class="ml-2 h-25">
                          <b-button v-if="!row.item.waiting_4_ai && row.item.stopped_typing" variant="dark" @click="row.item.editing = true">Edit</b-button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <b-button v-if="!row.item.waiting_4_ai" :disabled="row.item.working" size="sm" class="mt-2 mr-2" @click="createReply(row.item)">
                        Write Reply
                        <b-spinner v-if="row.item.working" small />
                      </b-button>
                      <b-button v-if="row.item.reply && !row.item.waiting_4_ai" variant="info" :disabled="row.item.working" size="sm" class="mt-2 mr-2" @click="rewriteReply(row.item)">
                        Re-write Reply
                        <b-spinner v-if="row.item.working" small />
                      </b-button>
                      <b-button v-if="row.item.reply && !row.item.waiting_4_ai" variant="outline-primary" :disabled="row.item.working" size="sm" class="mt-2" @click="saveReply(row.item)">
                        Post to Google
                        <b-spinner v-if="row.item.working" small />
                      </b-button>
                    </div>
                    <div v-if="row.item.openai_error" class="font-weight-bold text-danger">
                      {{row.item.openai_error}}
                    </div>
                  </template>
                  <template v-slot:cell(review_timestamp)="data">
                    {{ data.item.review_timestamp | datetime('MM/DD/YYYY HH:mm') }}
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalReviews }} reviews
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalReviews"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv';
import TypeWriter from '../../../src/components/Common/TypeWriter.vue';
export default {
  components:{
    VueJsonToCsv,
    TypeWriter
  },
  data() {
    return {
      json_export_labels:{

      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'author_title', label: 'Author Name', sortable: true, thStyle:{width:'175px'} },
          { key: 'review_rating', label: 'Rating', sortable: true },
          { key: 'review_text', label: 'Comment'},
          { key: 'review_timestamp', label:'Timestamp', sortable: true, thStyle:{width:'175px'}  },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          review_rating:'0',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      showDeleteModal: false,
      selectedBusiness: null,
      loadingBusinesses: false,
      loadingImpersonate: false,
      loadingRemove: null,
      showWelcomeModal: false,
    }
  },

  computed: {
    reviews() {
      return this.$store.getters['reviews/all'] || []
    },

    totalReviews() {
      return this.$store.getters['reviews/total']
    },
    meta() {
      return this.$store.getters['reviews/meta']
    },

    reload() {
      return this.$store.getters['reviews/reload']
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  watch: {
    reload: (val) => {
      console.log(val)
    },
  },

  created() {
    this.$store.dispatch('reviews/setCurrentPage', 'index')
  },

  mounted() {
    this.getReviews();
  },

  methods: {
    csvTitle(){
      return 'showmyrep-reviews'+Date.now()
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getReviews()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getReviews()
    },

    getReviews() {
      this.loadingReviews = true
      this.$store
          .dispatch('reviews/getAll', {
            queries: this.datatable.queries,
            page: 'index',
            locationId: this.user.location?.id
          })
          .then((res) => {
            this.business = res.business;
            this.loadingReviews = false
          })
          .catch(() => {
            this.loadingReviews = false
          })
    },
    createReply(review){
      review.working = true;
      review.writing_reply = true;
      review.waiting_4_ai = true;
      review.stopped_typing = false;
      review.openai_error = null;
      review.editing = false;
      this.$store
          .dispatch('reviews/getReply', {
            review: review.review_text,
            reviewers_name: review.author_title,
            review_stars: review.review_rating+" out of 5 stars. "
          })
          .then((res) => {
            review.reply = res.data.reply;
            review.working = false;
            review.waiting_4_ai = false;
            if(res.data.error){
              review.openai_error = res.dada.error;
            }
          })
          .catch(() => {
            review.working = false;
            review.writing_reply = false;
            review.waiting_4_ai = false;
            review.stopped_typing = true;
          })
    },
    rewriteReply(review){
      review.working = true;
      review.writing_reply = true;
      review.waiting_4_ai = true;
      review.stopped_typing = false;
      review.openai_error = null;
      review.editing = false;
      this.$store
          .dispatch('reviews/rewriteReply', {
            review: review.review_text,
            reviewers_name: review.author_title,
            review_stars: review.review_rating+" out of 5 stars. "
          })
          .then((res) => {
            console.log(res.data);
            review.reply = res.data.reply;
            review.working = false
            review.waiting_4_ai = false;
            if(res.data.error){
              review.openai_error = res.dada.error;
            }
          })
          .catch(() => {
            review.working = false;
            review.writing_reply = false;
            review.waiting_4_ai = false;
            review.stopped_typing = true;
          })
    },
    saveReply(review){
      review.working = true;
      this.$store
          .dispatch('reviews/saveReply', {
            review: review
          })
          .then((res) => {
            console.log(res.data);
            review.working = false;
            review.editing = false;
          })
          .catch(() => {
            review.working = false
          })
    },
  },
}
</script>
<style lang="scss" scoped>
  .status-filter{
    text-transform: capitalize;
  }
</style>
